import React from 'react';

import { SCREEN_EDGES } from '../../constants';
import { IUrl } from '../../types';
import { mediaUrl } from '../../utils';

export interface IPictureVariants {
    desktopNormal: IUrl|string,
    desktopSmall?: IUrl|string,
    tablet?: IUrl|string,
    mobile?: IUrl|string
}

interface IProps {
    image: IPictureVariants,
    alt: string,
    className?: string
}

export const Picture: React.FC<IProps> = ({ image, alt, className }) => {
    const { desktopNormal, desktopSmall, tablet, mobile } = image;

    const desktopNormalImage: string = (desktopNormal as IUrl).url ? mediaUrl((desktopNormal as IUrl).url) : desktopNormal as string;
    const desktopSmallImage: string = (desktopSmall as IUrl)?.url ? mediaUrl((desktopSmall as IUrl).url) : desktopSmall as string;
    const tabletImage: string = (tablet as IUrl)?.url ? mediaUrl((tablet as IUrl).url) : tablet as string;
    const mobileImage: string = (mobile as IUrl)?.url ? mediaUrl((mobile as IUrl).url) : mobile as string;

    return (
        <picture className={(className)}>
            <source srcSet={mobileImage || tabletImage || desktopSmallImage || desktopNormalImage} media={`(max-width: ${SCREEN_EDGES.tablet - 1}px)`} />
            <source srcSet={tabletImage || desktopSmallImage || desktopNormalImage} media={`(max-width: ${SCREEN_EDGES.desktopSmall - 1}px)`} />
            <source srcSet={desktopSmallImage || desktopNormalImage} media={`(max-width: ${SCREEN_EDGES.desktopNormal - 1}px)`} />
            <img src={desktopNormalImage} alt={alt} width="100%" height="100%" />
        </picture>
    );
};
